//
// alert.scss
//

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        --#{$prefix}alert-color: #{shift-color($value, 30%)};
        --#{$prefix}alert-bg: #{rgba($value, 0.18)};
        --#{$prefix}alert-border-color: #{rgba($value, 0.18)};
        .alert-link {
            --#{$prefix}alert-color: #{shade-color($value, 30%)};
        }
    }
}
