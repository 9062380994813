//
// buttons.scss
//


button:focus {
    outline: none;
}

.btn {
    .mdi {
        &:before {
            margin-top: -1px;
        }
    }

    i {
        display: inline-block;
    }
}

// Button White
.btn-white,
.btn-outline-light {
    --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-100);
    --#{$prefix}btn-border-color: var(--#{$prefix}gray-300);
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-100);
}

//
// Button labels
//

.btn-label {
    margin: -0.55rem 0.9rem -0.55rem -0.9rem;
    padding: 0.6rem 0.9rem;
    background-color: rgba(var(--#{$prefix}gray-900-rgb), 0.1);
}

.btn-label-right {
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
    padding: ($btn-padding-y) ($btn-padding-x);
    background-color: rgba(var(--#{$prefix}gray-900-rgb), 0.1);
    display: inline-block;
}

//
// Button Extra Small Size

//

.btn-xs {
    padding: 0.2rem 0.6rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
}


//
// Button Shadows
//
@each $color,
$value in $theme-colors {
    .btn-#{$color} {
        box-shadow: $btn-box-shadow rgba(var(--#{$prefix}#{$color}-rgb), 0.5);
    }
}

//
// Soft Buttons
//

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        --#{$prefix}btn-color: var(--#{$prefix}#{$color});
        --#{$prefix}btn-bg: rgba(var(--#{$prefix}#{$color}-rgb), 0.18);
        --#{$prefix}btn-border-color: rgba(var(--#{$prefix}#{$color}-rgb), 0.12);
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$color});
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$color});
        --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$color}-rgb);
        --#{$prefix}btn-active-color: #{$white};
        --#{$prefix}btn-active-bg: var(--#{$prefix}#{$color});
        --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$color});
        --#{$prefix}btn-active-shadow: var(--#{$prefix}btn-active-box-shadow);
        --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$color});
    }
}


// Dark Mode light and Seconadry button
body[data-theme="dark"] {

    .btn-light {
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-border-color: var(--#{$prefix}gray-300);
        --#{$prefix}btn-hover-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-300);
        --#{$prefix}btn-active-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-active-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-active-border-color: var(--#{$prefix}gray-300);
    }

    .btn-outline-dark {
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    }


    .btn-outline-light,
    .btn-outline-dark {
        --#{$prefix}btn-hover-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    }

    .btn-soft-dark {
        --#{$prefix}btn-hover-bg: var(--#{$prefix}light);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}light);
    }

}