//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
    height: 5px;
}

// Progress height medium
.progress-md {
    height: 8px;
}

// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height extra large
.progress-xl {
    height: 15px;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}

.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
        bottom: 0;
        position: absolute;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}
