//
// autocomplete.scss
//

.autocomplete-suggestions {
    border: $dropdown-border-width solid $dropdown-border-color;
    background-color: $dropdown-bg;
    cursor: default;
    overflow: auto;
    max-height: 200px !important;
    box-shadow: $box-shadow;
}

.autocomplete-suggestion {
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 5px;
}

.autocomplete-selected {
    background: var(--#{$prefix}gray-200);
    cursor: pointer;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: var(--#{$prefix}gray-900);
}

.autocomplete-group {
    padding: 5px;
    font-weight: $font-weight-medium;
    font-family: $font-family-secondary;
}

.autocomplete-group strong {
    font-weight: bold;
    font-size: 16px;
    color: var(--#{$prefix}gray-900);
    display: block;
}
